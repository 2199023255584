import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { appProperties } from '../util/appProperties';
import { addSubscription } from '../services/mailChimpService';
import PageLoader from './PageLoader';
import { EventType } from '@azure/msal-browser';

const RouteMiddleware = (props) => {
  const history = useHistory();
  const location = useLocation();
  let eventCallBack = null;
  if (props.instance.getActiveAccount()) {
    history.push('/');
  } else {
    eventCallBack = props.instance.addEventCallback(
      (event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          props.instance.setActiveAccount(account);
          let accounts = props.instance.getAllAccounts();
          let activeAcccount = props.instance.getActiveAccount();

          if (activeAcccount && activeAcccount.username) {
            props.fullStoryInstance.setUserVars({ email: activeAcccount.idTokenClaims.otherMails[0] });
          }

          const susbscribe = async () => {
            if (accounts.length > 0) {
              if (activeAcccount) {
                try {
                  const resp = await addSubscription(props.instance, appProperties.apiHost);
                  if (resp) {
                    if (location.pathname === '/claimAuth') {
                      let claimId = localStorage.getItem('claimUrl');
                      localStorage.removeItem('claimUrl');
                      history.push('/' + claimId);
                    } else {
                      history.push('/dashboard');
                    }
                  } else {
                    history.push('/');
                  }
                } catch (error) {
                  history.push('/');
                }
              } else {
                if (location.pathname === '/claimAuth') {
                  let claimId = localStorage.getItem('claimUrl');
                  localStorage.removeItem('claimUrl');
                  history.push('/' + claimId);
                } else {
                  history.push('/dashboard');
                }
              }
            } else {
              history.push('/');
            }
          };
          susbscribe();
        }
      },
      (error) => {
        console.log('error', error);
      }
    );
  }
  useEffect(() => {
    return () => {
      if (eventCallBack) {
        props.instance.removeEventCallback(eventCallBack);
      }
    };
  }, [props.instance, eventCallBack]);

  return <PageLoader />;
};

export default RouteMiddleware;
