import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import Home from '../features/home/Home';
import AddToTally from '../features/claim/AddToTally';
import Claim from '../features/claim/Claim';
import Dashboard from '../features/dashboard/Dashboard';

import '../styles/style.scss';
import './App.css';
import { AuthInteceptor } from '../components/authInterceptor';
import MyProfile from '../features/myProfile/MyProfile';
import AllTransactions from '../features/allTransactions/AllTransactions';
import PageLayout from '../components/layouts/PageLayout';
import RouteMiddleware from '../components/RouteMiddleware';

const Pages = ({ instance, fullStoryInstance }) => {
  return (
    <Switch>
      <Route exact path="/">
        <AuthenticatedTemplate>
          <Dashboard instance={instance} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Home instance={instance} />
        </UnauthenticatedTemplate>
      </Route>
      <Route path="/dashboard">
        <AuthInteceptor>
          <Dashboard instance={instance} />
        </AuthInteceptor>
      </Route>
      <Route path="/myprofile">
        <AuthInteceptor>
          <MyProfile instance={instance} />
        </AuthInteceptor>
      </Route>
      <Route path="/alltransactions">
        <AuthInteceptor>
          <AllTransactions />
        </AuthInteceptor>
      </Route>
      <Route path="/auth">
        <AuthInteceptor>
          <RouteMiddleware instance={instance} fullStoryInstance={fullStoryInstance} />
        </AuthInteceptor>
      </Route>
      <Route path="/claimAuth">
        <AuthInteceptor>
          <RouteMiddleware instance={instance} fullStoryInstance={fullStoryInstance} />
        </AuthInteceptor>
      </Route>
      <Route path="/:scanId/:slotId">
        <UnauthenticatedTemplate>
          <Claim instance={instance} />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AddToTally instance={instance} />
        </AuthenticatedTemplate>
      </Route>
      <Route exact path="/*">
        <AuthenticatedTemplate>
          <Dashboard instance={instance} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Home instance={instance} />
        </UnauthenticatedTemplate>
      </Route>
    </Switch>
  );
};

const App = ({ instance, fullStoryInstance }) => {
  return (
    <Router>
      <PageLayout instance={instance}>
        <Pages instance={instance} fullStoryInstance={fullStoryInstance} />
      </PageLayout>
    </Router>
  );
};

export default App;
