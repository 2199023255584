import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './util/azureConf';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { appProperties } from './util/appProperties';
import * as FullStory from '@fullstory/browser';
import { selectAccount } from './services/authService';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import PageLoader from './components/PageLoader';

const msalInstance = new PublicClientApplication(msalConfig);

FullStory.init({ orgId: '15K2BV', devMode: appProperties.environment === 'development' });

Sentry.init({
  dsn: appProperties.dsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: appProperties.environment,
  tracesSampleRate: 1.0
});

selectAccount(msalInstance);
ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<PageLoader />} //If not pass, nothing appears at the time of new version check.
  >
    <MsalProvider instance={msalInstance}>
      <App instance={msalInstance} fullStoryInstance={FullStory} />
    </MsalProvider>
  </CacheBuster>,
  document.getElementById('root')
);

serviceWorker.unregister();
